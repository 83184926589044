.App {
  text-align: center;
}

body {
  font-family: 'Fondamento', cursive;
}

.container {
  position: relative;
  width: 50%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #9c9191;
  overflow: hidden;
  opacity: .8;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.container:hover .overlay {
  height: 20%;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

h1 {
  margin-block-start: 0.67em;
  margin-block-end: 0.1em;
}

h3 {
  margin-block-start: .3em;
  margin-block-end: .3em;
}
h4 {
  margin-block-start: .3em;
  margin-block-end: .3em;
}

.copy{
  font-family: 'Libre Baskerville', serif;
  font-size: 23px;
  font-weight: lighter;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-size: 32px;
  color: #534f4f;
}

.horizontal {
  width: 25%
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a {
  color: inherit;
  text-decoration: none;
}

a:hover 
{
   color:inherit; 
   text-decoration:none; 
   cursor:pointer;  
}

.flex-container {
  display: flex;
  align-content: space-between;
}